import ReactDOM from 'react-dom'
import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import { Button, Row, Col } from 'react-materialize'
import { APERTURA_INTESTATARI } from '../../../../store/modules/forms/constants'
import { renderAutocomplete, renderServizioCheckbox } from '../../../../components/Forms/renderFields'
import ModalGeneric from '../../../../components/ModalGeneric'
import { Tooltip } from 'react-tippy'

const SERVICE_ID = 3
let idTipoServizio = []
let disabledService = []

const validate = (values, props = {}) => {
  const errors = {}
  const { users = [], OWcustomers0 = [], OWcustomers1 = [], OWcustomers2 = [] } = props
  const _user = [...users, ...OWcustomers0, ...OWcustomers1, ...OWcustomers2]
  const selectedService = idTipoServizio.reduce((res, cur) => {
    if (values[`check${cur}`]) {
      return [...res, cur]
    }
    return res
  }, [])
  if (values.cointestatario0) {
    const user = _user.find(u => u.idCliente === values.cointestatario0)
    if (!user) {
      errors.cointestatario0 = values.cointestatario0.length > 1 ? 'Clicca sulla lente per ulteriori risultati' : ' '
    } else if (!user.flagResidenzaItalia) {
      errors.cointestatario0 = 'Non è possibile selezionare come intestatario un cliente non residente in Italia.'
    } else if (user.flagUsaPerson) {
      errors.cointestatario0 =
        'Non è possibile selezionare come intestatario una US Person ai sensi della normativa FATCA.'
    }
  }
  if (values.cointestatario1) {
    const user = _user.find(u => u.idCliente === values.cointestatario1)
    if (!user) {
      errors.cointestatario1 = values.cointestatario1.length > 1 ? 'Clicca sulla lente per ulteriori risultati' : ' '
    } else if (!user.flagResidenzaItalia) {
      errors.cointestatario1 = 'Non è possibile selezionare come intestatario un cliente non residente in Italia.'
    } else if (user.flagUsaPerson) {
      errors.cointestatario1 =
        'Non è possibile selezionare come intestatario una US Person ai sensi della normativa FATCA.'
    }
  }
  if (values.cointestatario2) {
    const user = _user.find(u => u.idCliente === values.cointestatario2)
    if (!user) {
      errors.cointestatario2 = values.cointestatario2.length > 1 ? 'Clicca sulla lente per ulteriori risultati' : ' '
    } else if (!user.flagResidenzaItalia) {
      errors.cointestatario2 = 'Non è possibile selezionare come intestatario un cliente non residente in Italia.'
    } else if (user.flagUsaPerson) {
      errors.cointestatario2 =
        'Non è possibile selezionare come intestatario una US Person ai sensi della normativa FATCA.'
    }
  }
  if (selectedService.length === 0 || !values.cointestatario0) {
    errors.check1 = 'Almeno un servizio e un intestatario sono richiesti.'
  }
  return errors
}

export class IntestatariView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingComplete: false,
      OWcustomers0: false,
      OWcustomers1: false,
      OWcustomers2: false,
      showModal: false
    }
    this.OWcustomers0 = React.createRef()
    this.OWcustomers1 = React.createRef()
    this.OWcustomers2 = React.createRef()

    this.mapClientiToAutocomplete = this.mapClientiToAutocomplete.bind(this)
    this.savePratica = this.savePratica.bind(this)
    this.checkIntestatario = this.checkIntestatario.bind(this)
    this.buildOWCustomers = this.buildOWCustomers.bind(this)
    this.getOWCustomerList = this.getOWCustomerList.bind(this)
    this.mapDomainService = this.mapDomainService.bind(this)
    this.checkService = this.checkService.bind(this)
  }

  componentDidMount() {
    this.props.getDomainService(SERVICE_ID)
    this.props.getCustomerList()
    this.props.resetPratica()
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.getDomainServiceRequest.fetching &&
      prevProps.getClientiListRequest.fetching &&
      !this.props.getClientiListRequest.fetching
    ) {
      this.mapDomainService()
      this.setState({ loadingComplete: true })
    }

    if (
      this.props.getClientiOWListRequest &&
      prevProps.getClientiOWListRequest &&
      prevProps.getClientiOWListRequest.fetching &&
      !this.props.getClientiOWListRequest.fetching &&
      this.props.getClientiOWListRequest.dataLength === 0
    ) {
      this.setState({ showModal: true })
    }

    if (
      this.props.savePraticaRequest &&
      prevProps.savePraticaRequest &&
      prevProps.savePraticaRequest.fetching &&
      !this.props.savePraticaRequest.fetching &&
      !this.props.savePraticaRequest.error
    ) {
      this.props.history.push(`configurazione/${this.props.idPratica}`)
    }

    this.checkService()
  }

  mapDomainService() {
    this.props.domainServiceList.forEach(service => {
      disabledService[service.codiceTipoServizio] = false
      idTipoServizio[service.codiceTipoServizio] = service.codiceTipoServizio
    })
  }

  mapClientiToAutocomplete(altriIntestatari, checkProfilatura, customerType) {
    const { users, formValues = {} } = this.props
    let _users = this.state[customerType] ? [...users, ...this.props[customerType]] : [...users]
    return _users.reduce((res, user) => {
      if (altriIntestatari.some(i => formValues[i] === user.idCliente)) {
        return res
      }
      if (checkProfilatura && !user.flagProfilatura) {
        return res
      }
      if (altriIntestatari[0] === 'cointestatario0') {
        if (user.isPG) {
          return res
        }
      }

      let valueToSearch, label
      // PF
      if (user.nome && user.cognome) {
        valueToSearch = user.nome + ' ' + user.cognome + ' ' + user.nome + ' ' + user.codiceFiscale
        label = user.nome + ' ' + user.cognome
      }
      // PG/PD
      if (!user.nome && user.cognome) {
        valueToSearch = user.cognome + ' ' + user.codiceFiscale
        label = user.cognome
      }
      // Fallback
      if (!user.nome && !user.cognome) {
        valueToSearch = user.codiceFiscale
        label = valueToSearch
      }

      return [
        ...res,
        {
          ...user,
          key: user.idCliente,
          value: user.idCliente,
          valueToSearch,
          label
        }
      ]
    }, [])
  }

  buildOWCustomers(values) {
    const { OWcustomers0, OWcustomers1, OWcustomers2 } = this.props
    let _customers = [...OWcustomers0, ...OWcustomers1, ...OWcustomers2]
    return [0, 1, 2].reduce((res, val) => {
      if (values[`cointestatario${val}`]) {
        const customer = _customers.filter(
          user => user.idCliente === values[`cointestatario${val}`] && user.codClienteOW
        )[0]
        if (customer) {
          return [
            ...res,
            {
              idCliente: customer.idCliente,
              nome: customer.nome,
              cognome: customer.cognome,
              codiceFiscale: customer.codiceFiscale,
              codClienteOW: customer.codClienteOW,
              feqAbilitata: customer.feqAbilitata,
              tipoPraticaOB: customer.isPG ? 2 : 1,
              profilaturaMifid: customer.flagProfilatura,
              email: customer.email,
              cellulare: customer.cellulare
            }
          ]
        }
        return res
      }
      return res
    }, [])
  }

  getOWCustomerList(user, customerType) {
    if (!user || !isNaN(parseInt(user)) || user.length < 2) {
      return
    }
    this.props
      .getOWCustomerList(
        {
          querySearch: user
        },
        customerType
      )
      .then(() => {
        this.setState({ [customerType]: true })
        ReactDOM.findDOMNode(this[customerType])
          .getElementsByTagName('input')[0]
          .focus()
      })
  }

  async savePratica(values) {
    await this.props.saveOWCustomers(this.buildOWCustomers(values))
    this.props.saveAndProceed({
      codiciTipoServizio: idTipoServizio.reduce((res, cur) => {
        if (values[`check${cur}`]) {
          return [...res, cur]
        }
        return res
      }, []),
      intestatari: [0, 1, 2].reduce((res, cur) => {
        if (values[`cointestatario${cur}`]) {
          return [
            ...res,
            {
              idCliente: values[`cointestatario${cur}`],
              idRuolo: cur + 1
            }
          ]
        }
        return res
      }, [])
    })
  }

  checkIntestatario(intestatario) {
    const { users = [], OWcustomers0 = [] } = this.props
    let disabled = false
    let _users = this.state.OWcustomers0 ? [...users, ...OWcustomers0] : [...users]
    return _users.reduce((res, user) => {
      if (intestatario === '') {
        disabled = true
      } else {
        if (intestatario === user.idCliente && user.isPG) {
          disabled = true
        }
      }

      return disabled
    }, [])
  }

  getServiceTitle(service) {
    switch (service.codiceTipoServizio) {
      case 1:
        return '(1) ' + service.descrizione
      case 12:
      case 13:
        return '(2) ' + service.descrizione
      default:
        return service.descrizione
    }
  }

  checkService() {
    const { formValues } = this.props
    let _disabledService = [...disabledService]
    _disabledService[12] = !!formValues.check1
    _disabledService[1] = !!formValues.check12
    if (JSON.stringify(_disabledService) !== JSON.stringify(disabledService)) {
      disabledService = [..._disabledService]
      this.forceUpdate()
    }
  }

  render() {
    if (!this.state.loadingComplete) return null
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      errors,
      formValues = {},
      change,
      savePraticaRequest: { fetching },
      domainServiceList
    } = this.props
    const intestatari = this.mapClientiToAutocomplete(['cointestatario1', 'cointestatario2'], true, 'OWcustomers0')
    const cointestatari1 = this.mapClientiToAutocomplete(['cointestatario0', 'cointestatario2'], false, 'OWcustomers1')
    const cointestatari2 = this.mapClientiToAutocomplete(['cointestatario0', 'cointestatario1'], false, 'OWcustomers2')
    return (
      <div>
        <h2 className="title-sezione-config">CONFIGURAZIONE</h2>
        <div className="box-container box-intestatari">
          <ModalGeneric
            show={this.state.showModal}
            title="Attenzione!"
            text="Nessun risultato"
            handler={() => this.setState({ showModal: false })}
          />
          <form onSubmit={handleSubmit(this.savePratica)} noValidate>
            <Row className="collapsible-section-title">
              <h2>Intestatari</h2>
            </Row>
            <Row>
              <Col s={6}>
                <Field
                  name="cointestatario0"
                  size={6}
                  label="Intestatario"
                  threshold={0}
                  options={intestatari}
                  handleChange={(field, value) => {
                    change(field, value)
                    disabledService[7] = false
                    disabledService[9] = false
                    disabledService[10] = false
                    disabledService[14] = false

                    intestatari.map((item, i) => {
                      if (item.idCliente === value) {
                        // Check cliente eleggibile
                        if (!item.flagEligible) {
                          disabledService[14] = true
                          change(`check${idTipoServizio[14]}`, false)
                        }

                        // check PIR
                        if (!item.flagResidenzaItalia || item.codiceAltraCittadinanza || item.codClienteOW) {
                          disabledService[10] = true
                          change(`check${idTipoServizio[10]}`, false)
                        }
                        if (item.isPG) {
                          disabledService[7] = true
                          disabledService[10] = true
                          change(`check${idTipoServizio[7]}`, false)
                          change(`check${idTipoServizio[10]}`, false)
                        }
                        // check CDL e PIR
                        if (parseInt(item.codiceContrattoConsulenza) !== 2) {
                          disabledService[9] = true
                          disabledService[10] = true
                          change(`check${idTipoServizio[9]}`, false)
                          change(`check${idTipoServizio[10]}`, false)
                        }
                      }
                    })

                    change('cointestatario1', '')
                    change('cointestatario2', '')
                  }}
                  iconCallback={() => this.getOWCustomerList(formValues.cointestatario0, 'OWcustomers0')}
                  component={renderAutocomplete}
                  tooltipTitle="Clicca sulla lente per ulteriori risultati"
                  suppressFallbackError
                  searchByValue
                  ref={input => {
                    this.OWcustomers0 = input
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col s={6}>
                <Field
                  name="cointestatario1"
                  label="Cointestatario 1"
                  threshold={0}
                  options={cointestatari1}
                  handleChange={(field, value) => {
                    change(field, value)
                    disabledService[9] = false
                    disabledService[10] = false
                    disabledService[14] = false

                    // Check monointestatario
                    if (value) {
                      // check PIR
                      disabledService[10] = true
                      change(`check${idTipoServizio[10]}`, false)
                      // Check Goal Based Plan
                      disabledService[14] = true
                      change(`check${idTipoServizio[14]}`, false)
                    }

                    // check CDL (va controllato anche l'intestatario)
                    ;[...intestatari, ...cointestatari1].map((item, i) => {
                      if (
                        [value, formValues['cointestatario0']].indexOf(item.idCliente) !== -1 &&
                        parseInt(item.codiceContrattoConsulenza) !== 2
                      ) {
                        disabledService[9] = true
                        change(`check${idTipoServizio[9]}`, false)
                      }
                    })
                    change('cointestatario2', '')
                  }}
                  disabled={
                    this.checkIntestatario(formValues.cointestatario0) ||
                    (!formValues.cointestatario0 || errors.cointestatario0)
                  }
                  iconCallback={() => this.getOWCustomerList(formValues.cointestatario1, 'OWcustomers1')}
                  component={renderAutocomplete}
                  tooltipTitle="Clicca sulla lente per ulteriori risultati"
                  suppressFallbackError
                  searchByValue
                  ref={input => {
                    this.OWcustomers1 = input
                  }}
                />
              </Col>
              <Col s={6}>
                <Field
                  name="cointestatario2"
                  label="Cointestatario 2"
                  threshold={0}
                  options={cointestatari2}
                  handleChange={(field, value) => {
                    change(field, value)
                    disabledService[9] = false
                    // check CDL (vanno controllati anche intestatario e cointestatario1)
                    ;[...intestatari, ...cointestatari1, ...cointestatari2].map((item, i) => {
                      if (
                        [value, formValues['cointestatario0'], formValues['cointestatario1']].indexOf(
                          item.idCliente
                        ) !== -1 &&
                        parseInt(item.codiceContrattoConsulenza) !== 2
                      ) {
                        disabledService[9] = true
                        change(`check${idTipoServizio[9]}`, false)
                      }
                    })
                  }}
                  disabled={!formValues.cointestatario1 || errors.cointestatario1}
                  iconCallback={() => this.getOWCustomerList(formValues.cointestatario2, 'OWcustomers2')}
                  component={renderAutocomplete}
                  tooltipTitle="Clicca sulla lente per ulteriori risultati"
                  suppressFallbackError
                  searchByValue
                  ref={input => {
                    this.OWcustomers2 = input
                  }}
                />
              </Col>
            </Row>
            <Row className="collapsible-section-title m-top20">
              <h2>Servizi</h2>
            </Row>
            <Row className="buttons-servizio-box">
              {domainServiceList.map(service => (
                <Col key={`check${service.codiceTipoServizio}`}>
                  <div className="m-top20">
                    {service.tooltip ? (
                      <Tooltip theme="dark" title={service.tooltip}>
                        <Field
                          disabled={disabledService[service.codiceTipoServizio]}
                          name={`check${service.codiceTipoServizio}`}
                          title={this.getServiceTitle(service)}
                          className="filled-in"
                          component={renderServizioCheckbox}
                        />
                      </Tooltip>
                    ) : (
                      <Field
                        disabled={disabledService[service.codiceTipoServizio]}
                        name={`check${service.codiceTipoServizio}`}
                        title={this.getServiceTitle(service)}
                        className="filled-in"
                        component={renderServizioCheckbox}
                      />
                    )}
                  </div>
                </Col>
              ))}
            </Row>
            <Row>
              <ol className="notes m-top30">
                <li>Il servizio MAX UBS è riservato ai soli clienti con conto individuale</li>
                <li>Il servizio MAX/MAX FUND BNP è riservato ai soli clienti con conto Omnibus</li>
              </ol>
            </Row>
            <Row className="center-align m-top30">
              <Col s={12}>
                <Button
                  waves="light"
                  type="submit"
                  disabled={submitting || !isDirty || invalid || fetching}
                  className="margin-20">
                  Prosegui
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    )
  }
}

IntestatariView.propTypes = {
  history: PropTypes.object,
  users: PropTypes.array,
  getCustomerList: PropTypes.func,
  getClientiListRequest: PropTypes.object,
  saveAndProceed: PropTypes.func,
  savePraticaRequest: PropTypes.object,
  resetPratica: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  errors: PropTypes.object,
  formValues: PropTypes.object,
  change: PropTypes.func,
  idPratica: PropTypes.any,
  getOWCustomerList: PropTypes.func,
  saveOWCustomers: PropTypes.func,
  OWcustomers0: PropTypes.array,
  OWcustomers1: PropTypes.array,
  OWcustomers2: PropTypes.array,
  getClientiOWListRequest: PropTypes.object,
  getDomainService: PropTypes.func,
  domainServiceList: PropTypes.array,
  getDomainServiceRequest: PropTypes.object
}

export default connect(state => ({
  initialValues: {
    cointestatario0: ''
  },
  formValues: getFormValues(APERTURA_INTESTATARI)(state),
  isDirty: isDirty(APERTURA_INTESTATARI)(state),
  errors: getFormSyncErrors(APERTURA_INTESTATARI)(state)
}))(
  reduxForm({
    form: APERTURA_INTESTATARI,
    validate,
    touchOnBlur: true
  })(IntestatariView)
)
